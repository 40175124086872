import { Injectable } from "@angular/core"
import { Router } from "@angular/router"

import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { AccountService } from "../services/account.service"

/** If the user manages to navigate to a page without permission, redirect them to /orders */
@Injectable()
export class ChildAccountGuard  {
    constructor (private router: Router, private accountService: AccountService) {}

    canActivate (): Observable<boolean> {
        return this.accountService.selectedAccount.pipe(map(account => {
            if (!account || !account.data.permissionType || account.data.permissionType === "full") {
                return true
            } else {
                this.router.navigate(["/pages/orders"], {queryParamsHandling: "preserve"})
                return false
            }
        }))
    }
}