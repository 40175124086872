import { Component, HostListener, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core"

import { ModalService } from "../modal/modal.service"
import { AccountService } from "../services/account.service"
import { GlobalService, Alert } from "../services/global.service"
import { AuthService } from "../services/auth.service"

import { getTimezoneOffset, dateWithoutTime } from "../utils/date.utils"
import { isMobileTableSize, devLog } from "../utils/misc.utils"

import { Observable } from "rxjs"
import { mergeMap, tap } from "rxjs/operators"

type CvTouchEvent = TouchEvent // workaround for bug in Firefox/Safari https://stackoverflow.com/questions/58473921/why-cant-i-use-touchevent-in-safari

@Component({
  selector: "pages",
  templateUrl: "./pages.html",
  styleUrls: ["./pages.scss"]
})
export class Pages implements AfterViewInit {
  @ViewChild("main", {static: false}) main: ElementRef<HTMLElement>

  public loginMessage: string
  public copyrightMessage = ""
  public currentYear: number
  public alerts: Observable<Alert[]>
  public alertTimers: number[] = []
  public timezoneOffset = 0

  constructor(public modalService: ModalService, private accountService: AccountService, public globalService: GlobalService, authService: AuthService, cdr: ChangeDetectorRef) {
    this.currentYear = new Date().getFullYear()
    this.alerts = accountService.selectedAccount.pipe(
      mergeMap(account => {
        if (account) {
          return this.globalService.getAlerts(account)
        } else {
          return authService.getAdminDistrict().pipe(mergeMap(district => this.globalService.getAlerts(null, district ?? globalService.districtSubdomain)))
        }
      }),
      tap(alerts => {
        // Clear any existing timers to be replaced by new ones
        this.alertTimers.forEach(clearTimeout)
        this.alertTimers.length = 0

        alerts.forEach(alert => {
          if (alert.showStart && alert.showStop) {
            const now = new Date()
            now.setHours(now.getHours() - this.timezoneOffset)

            let interval: number
            if (now < alert.showStart) {
              interval = alert.showStart.getTime() - now.getTime()
            } else if (now > alert.showStop) {
              interval = alert.showStart.getTime() + 86400000 - now.getTime()
            } else {
              interval = alert.showStop.getTime() - now.getTime()
            }
            this.alertTimers.push(window.setTimeout(() => cdr.detectChanges(), interval))
          }
        })
      })
    )

    accountService.selectedAccount.subscribe(account => this.timezoneOffset = getTimezoneOffset(account?.layout.Configuration.TimeZone))
  }

  ngAfterViewInit () {
    this.accountService.getLoginMessage().then(message => {
      if (message) {
        this.loginMessage = message
        this.modalService.open("login-message")
      }
    })
    this.accountService.getCopyrightMessage().subscribe(message => this.copyrightMessage = message ? (message + " | ") : "")
  }

  public showAlert (alert: Alert): boolean {
    const now = new Date()
    now.setHours(now.getHours() - this.timezoneOffset)
    return alert.message?.length &&
      alert.active &&
      (!alert.showUntil || dateWithoutTime(now) < dateWithoutTime(alert.showUntil)) &&
      alert.message && alert.message[0] !== "." &&
      (!alert.showStart || alert.showStart < now) &&
      (!alert.showStop || alert.showStop > now) && 
      (!alert.hideDays || !alert.hideDays.split(",").includes(now.getDay().toString()))
  }
  public alertFixed (alert: Alert): boolean {
    return alert.mobileFixed && isMobileTableSize()
  }

  public showCredits () {
    this.modalService.open("credits")
  }

  public hideCredits () {
    this.modalService.close("credits")
  }

  public checkForRefresh () {
    this.accountService.checkForRefresh()
  }

  // Pull to refresh handling
  private touchStartY: number
  private touchLastY: number
  private touchTime: Date
  @HostListener("touchstart", ["$event"]) onTouchStart (event: CvTouchEvent) {
    // Don't do anything if already refreshing or not starting at top of page, or if sidebar is open
    if (!this.globalService.isSidebarCollapsed || this.globalService.isRefreshingPage || document.querySelector("html").scrollTop !== 0) { return }
    this.touchStartY = event.touches[0].clientY
    this.touchTime = new Date()
  }
  @HostListener("touchmove", ["$event"]) onTouchMove (event: CvTouchEvent) {
    this.touchLastY = event.touches[0].clientY
    if (this.touchStartY != null && this.touchStartY < this.touchLastY) {
      this.main.nativeElement.style.top = (this.touchLastY - this.touchStartY)/4 + "px"
      // Prevent jumpy scrolling when going back down from above the top
      event.preventDefault()
    } else {
      this.main.nativeElement.style.top = "0"
    }
  }
  @HostListener("touchend") onTouchEnd () {
    // Refresh if touch start was saved and pull is sufficiently long (both screen length and time)
    if (this.touchStartY != null && this.touchLastY != null && this.touchLastY - this.touchStartY > 100 && new Date().getTime() - this.touchTime.getTime() > 500) {
      this.refreshAccount()
    }
    this.touchStartY = null
    this.touchLastY = null
    this.touchTime = null

    this.main.nativeElement.style.transition = "all 0.25s"
    setTimeout(() => this.main.nativeElement.style.transition = "", 250)
    this.main.nativeElement.style.top = "0"
  }
  private refreshAccount () {
    this.globalService.isRefreshingPage = true

    this.accountService.refreshAccount().subscribe({
      next: () => {
        this.globalService.isRefreshingPage = false
      },
      error: error => {
        this.globalService.isRefreshingPage = false

        if (error.name === "TimeoutError") {
          devLog("Request timed out")
        } else {
          devLog(error)
        }
      }
    })
  }
}
