import { Injectable } from "@angular/core"
import { Router } from "@angular/router"

import { Observable, of, combineLatest } from "rxjs"
import { mergeMap, map } from "rxjs/operators"

import { AccountService } from "../services/account.service"
import { AuthService } from "../services/auth.service"

/** If the user doesn't have at least one linked account, prevent them from accessing the route and redirect them to /firstaccount */
@Injectable()
export class AccountGuard  {
    constructor (private router: Router, private accountService: AccountService, private authService: AuthService) {}

    canActivate (): Observable<boolean> {
        return this.accountService.getLinkedAccountInfo().pipe(mergeMap(accounts => {
            if (accounts.length > 0) {
                return of(true)
            } else {
                // Admins and title companies get redirected to their respective pages
                return combineLatest([this.authService.getAdminDistrict(), this.authService.isTitleCompany()]).pipe(map(([admin, title]) => {
                    let navTarget: string
                    if (admin) {
                        navTarget = "/pages/admin"
                    } else if (title) {
                        navTarget = "/pages/parcels"
                    } else {
                        navTarget = "/firstaccount"
                    }

                    this.router.navigate([navTarget], {queryParamsHandling: "preserve"})
                    return false
                }))
            }
        }))
    }
}