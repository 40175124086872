import { Injectable } from "@angular/core"
import { Router } from "@angular/router"

import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { AuthService } from "../services/auth.service"

/** If the user isn't a title company, prevent them from accessing the route and redirect them to /dashboard */
@Injectable()
export class TitleGuard  {
    constructor (private router: Router, private authService: AuthService) {}

    canActivate (): Observable<boolean> {
        return this.authService.isTitleCompany().pipe(map(title => {
            if (!title) {
                this.router.navigate(["/pages/dashboard"], {queryParamsHandling: "preserve"})
                return false
            } else {
                return true
            }
        }))
    }
}