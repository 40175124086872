import { Injectable } from "@angular/core"
import { Router } from "@angular/router"

import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { AngularFireDatabase } from "@angular/fire/compat/database"

import { AuthService } from "../services/auth.service"

/** If the user isn't a district admin, prevent them from accessing the route and redirect them to /dashboard */
@Injectable()
export class AdminGuard  {
    constructor (private router: Router, private authService: AuthService, private db: AngularFireDatabase) {}

    canActivate (): Observable<boolean> {
        return this.authService.getAdminDistrict().pipe(map(admin => {
            if (!admin) {
                this.router.navigate(["/pages/dashboard"], {queryParamsHandling: "preserve"})
                return false
            } else {
                return true
            }
        }))
    }
}