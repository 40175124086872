import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { AuthService } from "../services/auth.service"

@Injectable()
export class LoggedInGuard  {
    constructor (private authService: AuthService, private router: Router) {}

    canActivate (): Observable<boolean> {
        this.authService.isLoggedIn().subscribe(loggedIn => {
            if (!loggedIn) {
                this.router.navigate(["/login"], {queryParamsHandling: "preserve"})
            }
        })

        return this.authService.isLoggedIn()
    }
}

@Injectable()
export class LoggedOutGuard  {
    constructor (private authService: AuthService, private router: Router) {}

    canActivate (): Observable<boolean> {
        this.authService.isLoggedIn().subscribe(loggedIn => {
            if (loggedIn) {
                this.router.navigate(["/pages/dashboard"], {queryParamsHandling: "preserve"})
            }
        })

        return this.authService.isLoggedIn().pipe(map(state => !state))
    }
}