// Based on https://stackoverflow.com/questions/57271647/angular-2-error-loading-chunk-failed-many-times/58197858#58197858
import { ErrorHandler, Injectable } from "@angular/core"

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    handleError (error) {
        if (error && typeof error.message === "string" && /Loading chunk [\d]+ failed/.test(error.message)) {
            window.location.reload()
        } else {
            super.handleError(error)
        }
    }
}