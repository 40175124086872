import { Routes, RouterModule } from "@angular/router"
import { Pages } from "./pages.component"
import { ModuleWithProviders } from "@angular/core"

import { LoggedInGuard, LoggedOutGuard } from "../guards/login.guard"
import { AccountGuard } from "../guards/account.guard"
import { AdminGuard } from "../guards/admin.guard"
import { DisabledPageGuard } from "../guards/disabledPage.guard"
import { FirstAccountGuard } from "../guards/firstAccount.guard"
import { ChildAccountGuard } from "../guards/childAccount.guard"
import { TitleGuard } from "../guards/title.guard"

import { App } from "../app.component"

export const routes: Routes = [
  {
    path: "action",
    component: App,
    resolve: {
      url: "externalUrlRedirectResolver"
    },
    data: {
      externalUrl: "https://wateruseinfo-42167.firebaseapp.com/__/auth/action"
    }
  },
  {
    path: "login",
    canActivate: [LoggedOutGuard],
    loadChildren: () => import("app/pages/login/login.module").then(x => x.LoginModule)
  },
  {
    path: "register",
    canActivate: [LoggedOutGuard],
    loadChildren: () => import("app/pages/register/register.module").then(x => x.RegisterModule)
  },
  {
    path: "recovery",
    canActivate: [LoggedOutGuard],
    loadChildren: () => import("app/pages/recovery/recovery.module").then(x => x.RecoveryModule)
  },
  {
    path: "firstaccount",
    canActivate: [LoggedInGuard, FirstAccountGuard],
    loadChildren: () => import("app/pages/firstAccount/firstAccount.module").then(x => x.FirstAccountModule)
  },
  {
    path: "pages",
    component: Pages,
    canActivate: [LoggedInGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", loadChildren: () => import("./dashboard/dashboard.module").then(x => x.DashboardModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "deliveries", loadChildren: () => import("./deliveries/deliveries.module").then(x => x.DeliveriesModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "orders", loadChildren: () => import("./orders/orders.module").then(x => x.OrdersModule), canActivate: [AccountGuard, DisabledPageGuard] },
      { path: "balances", loadChildren: () => import("./balances/balances.module").then(x => x.BalancesModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "land", loadChildren: () => import("./landUsage/landUsage.module").then(x => x.LandUsageModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "reports", loadChildren: () => import("./reports/reports.module").then(x => x.ReportsModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "resources", loadChildren: () => import("./resources/resources.module").then(x => x.ResourcesModule), canActivate: [AccountGuard, DisabledPageGuard, ChildAccountGuard] },
      { path: "parcels", loadChildren: () => import("./parcels/parcels.module").then(x => x.ParcelsModule), canActivate: [TitleGuard] },
      { path: "settings", loadChildren: () => import("./settings/settings.module").then(x => x.SettingsModule) },
      { path: "customerprofile", loadChildren: () => import("./settings/settings.module").then(x => x.SettingsModule), canActivate: [AdminGuard] },
      { path: "admin", loadChildren: () => import("./admin/admin.module").then(x => x.AdminModule), canActivate: [AdminGuard] }
    ]
  }
]

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes)
