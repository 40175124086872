import { Injectable } from "@angular/core"
import { Router, ActivatedRouteSnapshot } from "@angular/router"

import { Observable, combineLatest } from "rxjs"
import { map } from "rxjs/operators"

import { PageService } from "../services/page.service"
import { AccountService } from "../services/account.service"

/** If the user manages to navigate to a disabled page, redirect them to the first enabled page */
@Injectable()
export class DisabledPageGuard  {
    constructor (private router: Router, private pageService: PageService, private accountService: AccountService) {}

    canActivate (route: ActivatedRouteSnapshot): Observable<boolean> {
        return combineLatest([this.pageService.getAvailablePages(), this.accountService.getDistrictPages(), this.accountService.selectedAccount]).pipe(map(([accountPages, districtPages, selectedAccount]) => {
            const path = route.url[0].path
            // If an account is set, use it, otherwise fall back to district settings
            const isAllowed = selectedAccount ? accountPages.some(page => page.path === path) : (districtPages[path] || path === "dashboard")

            if (!isAllowed) {
                this.router.navigate(["/pages/" + accountPages[0].path], {queryParamsHandling: "preserve"})
            }

            return isAllowed
        }))
    }
}