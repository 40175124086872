import { NgModule, Provider } from "@angular/core"
import { CommonModule } from "@angular/common"

import { routing } from "./pages.routing"
import { NgaModule } from "../theme/nga.module"
import { NgbAlertModule } from "@ng-bootstrap/ng-bootstrap"

import { Pages } from "./pages.component"

import { LoggedInGuard, LoggedOutGuard } from "../guards/login.guard"
import { AccountGuard } from "../guards/account.guard"
import { AdminGuard } from "../guards/admin.guard"
import { DisabledPageGuard } from "../guards/disabledPage.guard"
import { FirstAccountGuard } from "../guards/firstAccount.guard"
import { ChildAccountGuard } from "../guards/childAccount.guard"
import { TitleGuard } from "../guards/title.guard"
import { AdminService } from "../services/admin.service"
import { AuthService } from "../services/auth.service"
import { AccountService } from "../services/account.service"
import { RequestService } from "../services/request.service"
import { FileService } from "../services/file.service"
import { CardService } from "../services/card.service"
import { DashboardService } from "../services/dashboard.service"
import { MapService } from "../services/map.service"
import { OrdersService } from "../services/orders.service"
import { MessageService } from "../services/message.service"
import { GlobalService } from "../services/global.service"
import { ReportsService } from "../services/reports.service"
import { PageService } from "../services/page.service"

import { ModalModule } from "../modal/modal.module"
import { PipeModule } from "../pipes.module"

const externalUrlRedirectResolver: Provider = {
  provide: "externalUrlRedirectResolver",
  useValue: (route) => {
    let url = route.data.externalUrl
    // If the route has any query parameters, add them to the URL
    const params = Object.keys(route.queryParams).map(key => `${key}=${route.queryParams[key]}`).join("&")
    if (params) {
      url += `?${params}`
    }
    window.location.href = url
  }
}

@NgModule({
  imports: [CommonModule, NgaModule, routing, ModalModule, PipeModule, NgbAlertModule],
  declarations: [Pages],
  providers: [LoggedInGuard, LoggedOutGuard, AccountGuard, AdminGuard, DisabledPageGuard, FirstAccountGuard, ChildAccountGuard, TitleGuard, AdminService, AuthService, AccountService, RequestService, FileService, CardService, DashboardService, MapService, OrdersService, MessageService, GlobalService, ReportsService, PageService, externalUrlRedirectResolver]
})
export class PagesModule {
}