import { NgModule, Provider, ErrorHandler } from "@angular/core"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { GoogleMapsModule } from "@angular/google-maps"
import { provideIonicAngular } from "@ionic/angular/standalone"
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from "./app.routing"

// App is our top level component
import { App } from "./app.component"
import { AppState, InternalStateType } from "./app.service"
import { GlobalState } from "./global.state"
import { NgaModule } from "./theme/nga.module"
import { PagesModule } from "./pages/pages.module"

// Database imports
import { AngularFireModule } from "@angular/fire/compat"
import { AngularFireDatabaseModule } from "@angular/fire/compat/database"
import { AngularFireAuthModule } from "@angular/fire/compat/auth"
import * as firebase from "firebase/app"
import { environment } from "../environments/environment"

// Global services
import { ModalService } from "./modal/modal.service"
import { GlobalErrorHandler } from "./utils/errorhandler"

// Application wide providers
const APP_PROVIDERS: Provider[] = [
  AppState,
  GlobalState,
  ModalService,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
  provideIonicAngular()
]

export type StoreType = {
  state: InternalStateType
  restoreInputValues: () => void
  disposeOldHosts: () => void
}

firebase.initializeApp(environment.firebase)

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({ bootstrap: [App],
    declarations: [
        App
    ], imports: [// import Angular's modules
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgaModule.forRoot(),
        PagesModule,
        routing,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        GoogleMapsModule], providers: [
        APP_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

  constructor(public appState: AppState) {
  }
}
